<template>
  <div class="content article-data">
    <fm-form :inline="2" label-alone label-align="left" style="position: relative;">
      <fm-form-item label="文章类型">
        <fm-select absolute filterable :clearable="false" v-model="articleData.articleTypeId" :disabled="true">
          <fm-option v-for="item in articleTypeList" :key="item.id" :value="item.id" :label="item.name"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="编辑人">
        <fm-select absolute filterable :clearable="!readOnly" v-model="articleData.editUserId" :disabled="readOnly">
          <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="文章标题" style="width: 100%">
        <fm-input-new placeholder="文章标题" v-model="articleData.title" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="正文" style="width: 100%;">
        <!-- <fm-input-new style="height: 10rem;" type="textarea" placeholder="文章内容" v-model="articleData.content" :disabled="readOnly"/> -->
        <editor :readOnly="readOnly" :key="loadKey" :content="articleData.content" ref="editor" />
      </fm-form-item>
      <cover-img style="width: 33%;" :readOnly="readOnly" :articleData="articleData" @fileChange="fileChange"></cover-img>
      <fm-form-item label="附件" style="width: 66%;">
        <file-manage
          preview
          @uploadIng="uploadIng"
          :file-data-ids="fileIds"
          @addFile="addFile"
          @delFile="delFile"
          :sortable="!readOnly"
          @updateSort="ids => fileIds = ids"
          :funs="{get: true, upload: !readOnly, del: !readOnly}">
        </file-manage>
      </fm-form-item>
      <!-- <fm-form-item label="可查看人">
        <user-choose v-if="!readOnly" :userList="userList" v-model="articleData.getUserIdList" />
        <template v-else>
          <div v-for="item in articleData.getUserIdList" :key="item">
            {{userList.find(v => v.key === item).label}}
          </div>
        </template>
      </fm-form-item> -->
    </fm-form>
  </div>
</template>

<script>
import FileManage from '@/components/base/FileManage'
import Editor from '@/components/editor'
// import UserChoose from '../../../components/user'
import CoverImg from './coverImg'

export default {
  components: {
    FileManage,
    CoverImg,
    Editor,
    // UserChoose
  },
  props: {
    sourceData: {
      type: Object
    },
    articleTypeList: {
      type: Array
    },
    userList: {
      type: Array
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      articleData: {},
      fileIds: [],
      loading: false,
      loadKey: 0
    }
  },
  watch: {
    sourceData: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.sourceData))
        this.fileIds = data.fileIds ? data.fileIds.split(',').map(v => Number(v)) : []
        this.articleData = data
        this.articleData.getUserIdList = data.getUserIds ? data.getUserIds.split(',').map(v => Number(v)) : []
        this.loadKey++
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    uploadIng (uploadIng) {
      this.loading = uploadIng
    },
    addFile (data) {
      this.fileIds.push(data.id)
    },
    delFile (data) {
      this.fileIds = this.fileIds.filter(v => Number(v) !== data.id)
    },
    fileChange (data) {
      this.articleData.coverFileId = data.id
    },
    headFileChange (fileData) {
      this.$set(this.articleData, 'headFileId', fileData.id)
    },
    dealNumber (n) {
      if (typeof n === 'string') {
        n = n.replaceAll(' ', '')
      }
      if (n === 0) {
        return n
      }
      if (!n) {
        return null
      }
      if (isNaN(n)) {
        return 'error'
      }
      return n
    },
    async getFormData () {
      let data = Object.assign({}, this.articleData)
      data.fileIds = this.fileIds.length > 0 ? this.fileIds.join(',') : null
      data.getUserIds = data.getUserIdList.length > 0 ? data.getUserIdList.join(',') : null
      data.content = JSON.stringify(await this.$refs.editor.getJson())
      return data
    }
  }
}
</script>

<style scoped lang="less">
.article-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
  .form-item-f {
    width: 100% !important;
  }
}
</style>
