<template>
  <fm-form-item label="文章封面">
    <div class="img-c" v-loadingx="loadingFile" @click="!readOnly && $refs.upload.click()">
      <img :src="src" v-if="src" style="width: 100%"/>
      <div v-else class="no-img">
        <div>未上文章封面</div>
        <div v-if="!readOnly">点击上传</div>
      </div>
    </div>
    <input type="file" style="display: none;" @change="startUpload" ref="upload">
  </fm-form-item>
</template>

<script>
import {
  fileRequest
} from '@/api'

export default {
  props: {
    articleData: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingFile: false,
      src: null,
      fileId: null
    }
  },
  watch: {
    articleData: {
      handler () {
        this.fileId = this.articleData && this.articleData.coverFileId ? this.articleData.coverFileId : null
      },
      immediate: true,
      deep: true
    },
    fileId: {
      handler () {
        this.loadFile()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (file.type && file.type.includes('image')) {
          this.loadingFile = true
          let parm = new FormData()
          parm.append('file', file)
          let fileData = await fileRequest.upload(parm)
          await this.$emit('fileChange', fileData)
          // if (hisHeadFileId) {
          //   await fileRequest.del({'id': hisHeadFileId})
          // }
          // this.$notice.success({
          //   title: '系统提示',
          //   desc: '证件照上传完成。'
          // })
          this.loadingFile = false
          this.fileId = fileData.id
        } else {
          this.$notice.info({
            title: '系统提示',
            desc: '请选择图片文件。'
          })
        }
      }
    },
    async loadFile () {
      this.src = null
      if (!this.fileId) {
        return
      }
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.fileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        this.src = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
.img-c {
  height: 144px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #eee;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  img {
    max-height: 144px;
    max-width: 100%;
  }
}

.worker-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
}
</style>
